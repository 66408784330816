import { useDispatch, useSelector } from 'react-redux'
import {
  actionPush,
  actionPushFailed,
  actionPushSuccess,
  actionToggle
} from '../../../../redux/actions/navigation'
import { v4 as uuidv4 } from 'uuid'

export const useNavigation = () => {
  const { data } = useSelector(state => state.navigationReducer)
  const dispatch = useDispatch()

  /**
* Function for push tabs in stack
* @author   ehernandez
* @param    {Object} item   item for add
* @param    {Object} item.componente    component for open when tab is pushed
* @param    {Object} item.id
* @param    {Object} item.nombre   name for add to tab
* @param    {Object} params aditional params for tabs - OPTIONAL
*/
  const push = (item, params = {}) => {
    dispatch(actionPush())
    try {
      const stackItem = {
        ...item,
        active: true,
        key: uuidv4() // create custom id for tab
      }
      dispatch(actionPushSuccess(createStack(stackItem)))
    } catch (err) {
      actionPush(actionPushFailed())
    }
  }

  /**
* Function when toggle o cliked tab
* @author   ehernandez
* @param    {Object} item
* @param    {Object} item.id
* @param    {Object} item.active
*/
  const toggle = (item) => {
    const stack = data.filter(tab => {
      if (item.key === tab.key) {
        tab.active = true
      } else {
        tab.active = false
      }
      return tab
    })
    dispatch(actionToggle(stack))
  }

  /**
* Function for create stack array
* @author   ehernandez
* @param    {Object} item
*/
  const createStack = (item) => {
    const stack = data.filter(i => {
      i.active = false
      return i
    })

    stack.push(item)
    return stack
  }

  /**
* Function delte tab of stack
* @author   ehernandez
* @param    {Object} item
*/
  const close = (item) => {
    const stack = []
    data.forEach((tab, index) => {
      if (tab.key === item.key) {
        if (item.active) {
          const prev = index - 1
          if (prev >= 0) {
            stack[index - 1].active = true
          }
        }
      } else {
        stack.push(tab)
      }
    })

    dispatch(actionToggle(stack))
  }

  return { push, toggle, data, close }
}

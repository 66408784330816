import React from 'react'
import PropTypes from 'prop-types'
import {
  Sidebar,
  // CustomHeaderLayout,
  // Paragraphs,
  NavTab,
  Tab
} from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import { LayoutContainer } from './styles'

import useNavigation from '../../../StackNavigator/hooks/useNavigation'
import useMenu from '../../../../hooks/useMenu'
import CustomHeader from '../../../../../components/CustomHeader'

const ThemeXf = ({ children, onHandleClickOnSoftware, onHandleClickOnMenu }) => {
  const navigation = useNavigation()
  const { data } = useMenu()
  console.log('data', data)

  const itemsMenu = data
    ? data?.filter((item) => {
      if (item.id === 1612) {
        item.children = item.children.filter((item) => {
          if (item.id !== 1614) {
            return item
          }
          return null
        })
      }
      return item
    })
    : []
  return (
    <LayoutContainer className="layout tw-h-screen tw-overflow-hidden tw-relative tw-flex tw-flex-col tw-bg-gray-50">
      <div className="tw-absolute tw-w-full tw-top-0">
        <div className="tw-z-20 tw-relative">
          <CustomHeader />
        </div>
        <div className="tw-z-10 tw-relative">
          <NavTab>
            {navigation.data.map((tab, idx) => (
              <Tab
                key={idx}
                name={tab.nombre}
                active={tab.active}
                onPress={() => navigation.toggle(tab)}
                onClose={() => navigation.close(tab)}
              />
            ))}
          </NavTab>
        </div>
      </div>
      <div className="tw-relative tw-h-full">
        <Sidebar
          onSelectSoftware={onHandleClickOnSoftware}
          position={'fixed'}
          data={itemsMenu}
          onClickItemMenu={onHandleClickOnMenu}
        />

        <div className="tw-h-full tw-w-full">{children}</div>
      </div>
    </LayoutContainer>

  )
}

ThemeXf.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onHandleClickOnMenu: PropTypes.func,
  onHandleClickOnSoftware: PropTypes.func
}

export default ThemeXf

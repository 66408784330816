import styled from 'styled-components'

export const LayoutContainer = styled.div`
    margin-left: 50px;
    padding-top: 86px;

    & .Sidebar-small .BtnModule img{
        width: 20px;
        height: 20px;
    }
`

import { NAVIGATION_PUSH, NAVIGATION_PUSH_FAILED, NAVIGATION_PUSH_SUCCESS, NAVIGATION_TOGGLE } from '../constants/navigation'

export const actionPush = () => ({
  type: NAVIGATION_PUSH
})

export const actionPushSuccess = (stack) => ({
  type: NAVIGATION_PUSH_SUCCESS,
  stack
})

export const actionPushFailed = (stack) => ({
  type: NAVIGATION_PUSH_FAILED,
  stack
})

export const actionToggle = (stack) => ({
  type: NAVIGATION_TOGGLE,
  stack
})

import login from '../../../../modules/Login/locales/es/index.json'
import labels from './labels.json'
import actions from './actions.json'
import errors from './errors.json'
import menu from './menu.json'

export const es = {
  login,
  labels,
  actions,
  errors,
  menu
}

import { SET_APPS, SET_INFO_CORPORATE } from '../constants/corporate'

const initialState = {
  loading: false,
  apps: [],
  info: null
}

const corporateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INFO_CORPORATE:
      return {
        ...state,
        info: action.data
      }
    case SET_APPS:
      return {
        ...state,
        apps: action.data

      }
    default:
      return state
  }
}

export default corporateReducer

import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

// redux persist
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { version } from '../../../package.json'
// mandatory for react > 0.60+
// import AsyncStorage from '@react-native-community/async-storage';

// redux-logger
import { createLogger } from 'redux-logger'

// reducers
import Reducers from './reducers'

const logger = createLogger({
  collapsed: true
})

const middlewares = []

const persistConfig = {
  key: `root-${version}`,
  storage,
  whitelist: [
    'userReducer',
    'colorsReducer'
  ]
}

const persistedReducer = persistReducer(persistConfig, Reducers)

middlewares.push(logger)

// saga init

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
)

const persistor = persistStore(store)

export { store, persistor }

export const links = [
  {
    id: 1,
    name: 'Novedades',
    link: 'https://apps.xarxafarma.com/guest/pharmacy/news'
  },
  {
    id: 2,
    name: 'Gestión de compras',
    link: 'https://apps.xarxafarma.com/guest/pharmacy/platform'
  },
  {
    id: 3,
    name: 'Business intelligence',
    link: 'https://apps.xarxafarma.com/guest/pharmacy/bi'
  },
  {
    id: 4,
    name: 'Servicios',
    link: 'https://apps.xarxafarma.com/guest/pharmacy/services/index'
  },
  {
    id: 5,
    name: 'Administración',
    link: 'https://apps.xarxafarma.com/guest/pharmacy/administration'
  },
  {
    id: 6,
    name: 'Comunícate',
    link: 'https://apps.xarxafarma.com/guest/pharmacy/communication'
  }
]

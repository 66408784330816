import {
  Paragraphs,
  Spinner
} from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import React, { useEffect, useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { actionLogoutUser, actionSetTokenUser } from '../../../../infrastructure/redux/actions/user'
// import PropTypes from 'prop-types'

const AuthScreen = () => {
  const { token } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(actionLogoutUser())
  }, [])

  useEffect(() => {
    if (token) {
      dispatch(actionSetTokenUser({ token }))
      setTimeout(() => {
        if (token) { history.push('/') } else { history.push('/login') }
      }, 300)
    }
  }, [])
  return (
    <div className="auth-screen tw-h-screen">
      <div className="tw-flex tw-h-full tw-items-center tw-justify-center tw-flex-col">
        <div>
          <Spinner color={'#333'} />
        </div>
        <div className="tw-text-center tw-mt-4">
          <Paragraphs>Autologin</Paragraphs>
          <Paragraphs>Comprobando credenciales</Paragraphs>
        </div>
      </div>
    </div>
  )
}

AuthScreen.propTypes = {}

export default AuthScreen

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RECURSOS } from '../../constants/api'
import useMutation from '../useMutation'

export const useMenu = () => {
  const [data, setData] = useState(null)
  const [getMenus] = useMutation(RECURSOS)
  const { user } = useSelector(state => state.userReducer)
  /**
   * get Menu items
   *
   *
   * @author   ehernandez
   */

  const getMenuList = async () => {
    const { success, data: menu } = await getMenus({ method: 'get' })
    if (success) {
      if (user?.username?.includes('_AUX')) {
        const only = [1609, 1612, 1600, 1613, 1614, 1615, 1601, 1610, 1611]
        const newMenu = menu.filter(item => {
          if (only.includes(item.id)) {
            item.children = item.children.filter(child => {
              if (only.includes(child.id)) {
                return child
              }
              return null
            })
            return item
          }
          return null
        })
        setData(newMenu)
      } else {
        setData((menu))
      }
    }
  }

  /**
   * initialice funtion getmenus
   *
   *
   * @author   ehernandez
   * @param   {Object} item
   */

  useEffect(() => {
    getMenuList()
  }, [])

  return { data }
}

import { useDispatch } from 'react-redux'
import { CORPATIVE_INFO } from '../../constants/api'
import { actionSetColors, actionSetColorsFailed, actionSetColorsSuccess } from '../../redux/actions/colors'
import useMutation from '../useMutation'

const CODES = {
  xarxafarma: '/8c55fd70-040f-4961-bb53-60155cb05631',
  fedefarma: '/bf2b6de2-fbb8-481f-b518-bc139c03c666'
}

export const useComporativeColor = () => {
  const [handleGetColors] = useMutation(CORPATIVE_INFO)
  const dispatch = useDispatch()
  // const { theme } = useSelector(state => state.colorsReducer)

  /**
   * Get colors for group corporative
   * and update state into redux and local storage
   * if not have theme, it is default code
   *
   *
   * @author   ehernandez
   */
  const getCorporativeInfo = async (theme) => {
    console.log(theme)
    dispatch(actionSetColors())
    const { success, data } = await handleGetColors({ method: 'get', addToURL: CODES[theme] || CODES.fedefarma })
    if (!success) {
      dispatch(actionSetColorsFailed())
      return {
        success
      }
    }
    dispatch(actionSetColorsSuccess(data))
  }

  return { getCorporativeInfo }
}

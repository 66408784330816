import React from 'react'
import PropTypes from 'prop-types'
import { LayoutContainer } from './styles'
import {
  Sidebar,
  CustomHeaderLayout,
  Paragraphs,
  NavTab,
  Tab
} from '@labelgrupnetworks/02379_sertec_sfera_tools.git'
import useNavigation from '../../../StackNavigator/hooks/useNavigation'
import { useUser } from '../../../../hooks/useUser/useUser'
import useMenu from '../../../../hooks/useMenu'
import { useSelector } from 'react-redux'

const ThemeDefault = ({
  children,
  onHandleClickOnSoftware,
  onHandleClickOnMenu
}) => {
  const { data } = useMenu()
  const navigation = useNavigation()
  const { logoutUser } = useUser()
  const { user } = useSelector(state => state.userReducer)
  return (
    <LayoutContainer className="layout theme-default tw-h-screen tw-overflow-hidden tw-relative tw-flex tw-flex-col tw-bg-gray-50">
      <Sidebar
        onSelectSoftware={onHandleClickOnSoftware}
        position={'fixed'}
        data={data}
        onClickItemMenu={onHandleClickOnMenu}
      />
      <div className="tw-absolute tw-w-full tw-top-0">
        <CustomHeaderLayout
          linkLogo="https://particulars.xarxafarma.com/pharmacy/home"
          // logo={colors?.logo_sm || logo}
          user={user}
          onCloseSesion={() => logoutUser()}
        >
          <div className="tw-flex tw-items-center tw-justify-end tw-w-full">
            <Paragraphs className="tw-text-white tw-px-3" weight="bold">
              {/* {info?.nombre} */}
            </Paragraphs>
          </div>
        </CustomHeaderLayout>
        <NavTab>
          {navigation.data.map((tab, idx) => (
            <Tab
              key={idx}
              name={tab.nombre}
              active={tab.active}
              onPress={() => navigation.toggle(tab)}
              onClose={() => navigation.close(tab)}
            />
          ))}
        </NavTab>
      </div>
      <div className="tw-h-full tw-w-full">{children}</div>
    </LayoutContainer>
  )
}

ThemeDefault.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onHandleClickOnSoftware: PropTypes.func,
  onHandleClickOnMenu: PropTypes.func
}

export default ThemeDefault

import AuthScreen from '../../modules/Auth/pages/AuthScreen'
import LoginScreen from '../../modules/Login/pages/LoginScreen/LoginScreen'
import Layout from '../components/Layout'
import StackNavigator from '../components/StackNavigator/StackNavigator'

const authProtectedRoutes = [{ path: '/', component: StackNavigator, id: 2, layout: Layout }]

const publicRoutes = [
  { path: '/auth/:token', component: AuthScreen, id: 0 },
  { path: '/login', component: LoginScreen, id: 1 }

]

export { authProtectedRoutes, publicRoutes }
